<template>
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            Name
          </th>
          <th class="text-left">
            Value
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-for="data in items">
          <tr
            v-if="data.value"
            :key="data.name"
            :style="{ 'background-color': timeRowColor(item) }"
          >
            <td>{{ data.name }}</td>
            <td>
              <v-btn
                v-if="data.link"
                link
                :to="data.link"
                color="primary"
              >
                {{ data.value }}
              </v-btn>

              <template v-else>
                {{ data.value }}
              </template>
            </td>
          </tr>
        </template>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import moment from '@/plugins/moment'
import getActivityStatus from '@/kit/helpers/activityStatus'
import { SENT, getStatusName } from '@/kit/dictionaries/timesSendStatus'

export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    items() {
      const data = [
        {
          name: 'ID',
          value: this.item.id
        },
        {
          name: 'Send time',
          value: this.formatDatetimeLocal(this.item.sendTime)
        },
        {
          name: 'Sent time',
          value: this.item.sentTime
            ? this.formatDatetimeLocal(this.item.sentTime)
            : null
        },
        {
          name: 'Appointment Date',
          value: this.item.nextAppointmentDate
            ? this.item.nextAppointmentDate
            : null
        },
        {
          name: 'Appointment Time',
          value: this.item.nextAppointmentTime
            ? this.item.nextAppointmentTime
            : null
        },
        {
          name: 'Status',
          value: getStatusName(this.item.sendStatus)
        },
        {
          name: 'Error message',
          value: this.item.errorMessage
        },
        {
          name: 'Reminder',
          value: this.reminderId,
          link: '/dashboard/admin/reminders/' + this.reminderId
        }
      ]
      if (this.item.sendStatus === 3) {
        data.splice(-1, 0, {
          name: 'Page opened, qt',
          value: getActivityStatus(this.item, 'pageOpened')
        })
        data.splice(-1, 0, {
          name: 'Activity started',
          value: getActivityStatus(this.item, 'started')
        })
        data.splice(-1, 0, {
          name: 'Activity progress, %',
          value: getActivityStatus(this.item, 'progress')
        })
        data.splice(-1, 0, {
          name: 'Activity complete',
          value: getActivityStatus(this.item, 'complete')
        })
      }

      return data
    },
    reminderId() {
      if (this.item.schedule) {
        return this.item.schedule.id
      }

      return null
    }
  },
  methods: {
    getActivity(item, type) {
      if (!item.schedule.hasOwnProperty('link')) {
        return 'n/a'
      }

      if (item.sendStatus !== 3) {
        return '-'
      }

      switch (type) {
        case 'pageOpened':
          return item.pageOpened || 0
        case 'started':
          return item.activity && item.activity.started ? 'Yes' : 'No'
        case 'progress':
          return item.activity && item.activity.progress
            ? item.activity.progress
            : 0
        case 'complete':
          return item.activity && item.activity.progress === 100 ? 'Yes' : 'No'
        default:
          return ''
      }
    },
    timeRowColor(time) {
      if (moment(time.sendTime.toDate()) < moment()) {
        return time.sendStatus === SENT ? '#E8F5E9' : '#FFEBEE'
      }
    }
  }
}
</script>
