<template>
  <dashboard-content full-width>
    <v-row v-if="item">
      <v-col class="col-12 py-0">
        <h1 class="mb-4">
          Notification
        </h1>

        <v-row>
          <v-col class="col-12">
            <v-row justify="space-between">
              <v-col class="col-auto">
                <v-btn
                  link
                  :to="{ name: 'notifications' }"
                  color="primary"
                >
                  <v-icon class="mr-2">
                    {{ mdiChevronLeft }}
                  </v-icon> Back
                </v-btn>
              </v-col>
            </v-row>
          </v-col>

          <v-col class="col-12">
            <v-card>
              <v-card-text>
                <v-tabs
                  v-model="tab"
                  background-color="transparent"
                  show-arrows
                >
                  <v-tab href="#general">
                    General info
                  </v-tab>
                  <v-tab href="#sessions">
                    Sessions
                  </v-tab>
                  <v-tab href="#logs">
                    Logs
                  </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">
                  <v-tab-item
                    class="my-4"
                    value="general"
                  >
                    <tab-general :item="item" />
                  </v-tab-item>

                  <v-tab-item
                    class="my-4"
                    value="sessions"
                  >
                    <tab-sessions :sessions="sessions" />
                  </v-tab-item>

                  <v-tab-item
                    class="my-4"
                    value="logs"
                  >
                    <tab-logs :notification-ref="notificationRef" />
                  </v-tab-item>
                </v-tabs-items>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </dashboard-content>
</template>

<script>
import { mdiChevronLeft } from '@mdi/js'
import moment from '@/plugins/moment'
import DashboardContent from '@/components/dashboard/DashboardContent'
import TabGeneral from '@/views/dashboard/admin/notifications/TabGeneral'
import TabSessions from '@/views/dashboard/admin/notifications/TabSessions'
import TabLogs from '@/views/dashboard/admin/notifications/TabLogs'

export default {
  components: {
    TabLogs,
    TabGeneral,
    TabSessions,
    DashboardContent
  },
  data() {
    return {
      mdiChevronLeft,
      moment,
      tab: 'general',
      id: null,
      item: null,
      sessions: [],
      notificationRef: null
    }
  },
  mounted() {
    this.id = this.$route.params.id
    this.notificationRef = this.$firebase
      .firestore()
      .collection('times')
      .doc(this.id)

    this.$bind('item', this.notificationRef).then(() => {
      this.$bind(
        'sessions',
        this.$firebase
          .firestore()
          .collection('sessions')
          .where('time', '==', this.notificationRef.id)
          .orderBy('progress', 'desc')
      )
    })
  }
}
</script>
