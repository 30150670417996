<template>
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            Started
          </th>
          <th class="text-left">
            Progress, %
          </th>
          <th class="text-left">
            Duration, sec
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="session in sessions"
          :key="session.id"
          :style="{ 'background-color': timeRowColor(session) }"
        >
          <td>{{ session.started ? 'Yes' : 'No' }}</td>
          <td>{{ session.progress }}</td>
          <td>{{ session.duration }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  props: {
    sessions: {
      type: Array,
      required: true
    }
  },
  methods: {
    timeRowColor(session) {
      return session.progress === 100 ? '#E8F5E9' : ''
    }
  }
}
</script>
